/* Root variables */
:root {
  --primary-color: #d32f2f;
  --secondary-color: #388e3c;
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --background-dark: #e0e0e0;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;
  --accent-color: #d32f2f;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-color: rgba(211, 47, 47, 0.1); 
  --font-primary: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  --font-messages: 'Poppins', 'Inter', 'Roboto', sans-serif;
  --button-hover-color: #b71c1c;
  --sidebar-bg: #1c1c1c;
  --sidebar-text: #e0e0e0;
  --sidebar-hover: #2a2a2a;
  --sidebar-active: #333333;
  --sidebar-border: #3a3a3a;
}

/* Global styles */
body {
  height: 100vh; /* Fallback for browsers that don't support dvh */
  height: 100dvh; /* Dynamic viewport height */
  min-height: -webkit-fill-available;
  font-family: var(--font-primary);
}

html {
  height: -webkit-fill-available;
}

/* Layout */
.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback */
  height: 100svh; /* Small viewport height */
  background-color: var(--background-main);
  width: 100%;
  color: var(--text-primary);
  overflow: hidden;
}

.chat-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease, filter 0.3s ease;
  height: 100%;
  overflow: hidden;
}

.chat-area.sidebar-open {
  margin-left: 250px;
}

@media (max-width: 480px) {
  .chat-area.sidebar-open {
    background-color: #242424;
    opacity: 0.7;
    filter: brightness(0%);
  }
}
/* Input area */
.input-area {
  backdrop-filter: blur(10px);
  outline: none;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: 1rem;
  background-color: rgba(var(--background-light-rgb), 0.8);
  box-shadow: 0 -2px 10px rgba(var(--shadow-color-rgb), 0.1);
}

.input-wrapper {
  outline: none;
  display: flex;
  align-items: center;
  background-color: var(--background-main);
  border-radius: 24px;
  padding: 0.5rem;
  box-shadow: 0 2px 6px rgba(var(--shadow-color-rgb), 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid #1c1c1c;
}

.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;  /* Implement a transparent border */
  outline: none;  /* Remove outline */
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.chat-input:focus {
  border: 1px solid transparent;  /* Ensure transparent border on focus */
  outline: none;  /* Ensure no outline on focus */
}

.input-wrapper:focus-within {
}

.input-area input {
  flex-grow: 1;
  padding: 0.75rem 1.5rem;
  background: transparent;
  font-size: 1rem;
  border: none;
  outline: none;
}

.input-area input:focus {
  outline: none;
  border: none;
  background-color: white;
}

textarea:focus, input:focus{
  outline: none;
  border: none;
}



/* Header */
.chat-header {
  flex-shrink: 0;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  height: 61px;
  background-color: var(--sidebar-bg);
  box-shadow: 0 2px 4px var(--shadow-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--sidebar-text);
}

.menu-button,
.settings-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--sidebar-text);
}

.menu-button:hover,
.settings-button:hover {
  background: none !important;
}

.logo2 {
  display: flex;
  justify-content: center;
  max-width: 180px;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
}

/* Messages container */
.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 40%;
}

/* Message styles */
.message {
  max-width: 80%;
  margin-bottom: 1.5rem;
  padding: 1rem 1.25rem;
  border-radius: 18px;
  line-height: 1.6;
  animation: fadeIn 0.3s ease;
  box-shadow: 0 2px 5px var(--shadow-color);
  font-family: var(--font-messages);
  font-weight: 500;
}

.message.user {
  align-self: flex-end;
  margin-left: auto;
  background-color: var(--primary-color);
  color: var(--text-light);
}

.message.ai {
  align-self: flex-start;
  background-color: var(--sidebar-hover);
  color: var(--sidebar-text);
  margin-right: auto;
}

.message-content {
  font-size: 1rem;
  line-height: 1.6;
  letter-spacing: 0.015em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.message-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  opacity: 0.7;
}

.empty-chat {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  color: #333;
}

.empty-chat h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.empty-chat p {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.suggestion-list {
  list-style-type: none;
  padding: 0;
}

.suggestion-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.suggestion-item:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.suggestion-item svg {
  margin-right: 1rem;
  color: #4a90e2;
}

.suggestion-item span {
  font-size: 1rem;
}

.home-button{
  color: black;
}

/* Markdown styles */
.ai-response-heading {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
  font-family: var(--font-primary);
  color: var(--sidebar-text);
  line-height: 1.2;
}

.ai-response-heading-1 { font-size: 2em; }
.ai-response-heading-2 { font-size: 1.75em; }
.ai-response-heading-3 { font-size: 1.5em; }

.ai-response-paragraph {
  margin-bottom: 1.25rem;
  color: var(--sidebar-text);
  line-height: 1.8;
  font-size: 1.05rem;
}

.ai-response-strong {
  font-weight: 700;
  color: var(--accent-color);
}

.ai-response-list {
  margin-bottom: 1.25rem;
  padding-left: 1.75rem;
  color: var(--sidebar-text);
}

.ai-response-list-item {
  margin-bottom: 0.75rem;
  line-height: 1.6;
}

.ai-response-blockquote {
  border-left: 4px solid var(--primary-color);
  padding: 1rem 1.5rem;
  margin: 1.5rem 0;
  font-style: italic;
  color: var(--sidebar-text);
  background-color: var(--sidebar-hover);
  border-radius: 0 8px 8px 0;
  font-size: 1.05rem;
  line-height: 1.6;
}

.ai-response-link {
  color: var(--accent-color);
  text-decoration: none;
  transition: color 0.2s ease, border-bottom 0.2s ease;
  border-bottom: 1px solid transparent;
}

.ai-response-link:hover {
  color: var(--button-hover-color);
  border-bottom: 1px solid var(--button-hover-color);
}

.ai-response-hr {
  border: none;
  height: 2px;
  background-color: var(--sidebar-border);
  margin: 2rem 0;
}

.ai-response-inline-code {
  background-color: #f9f9f9;
  padding: 0.2em 0.4em;
  border-radius: 4px;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 0.9em;
  color: var(--accent-color);
}

.ai-response-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}

.ai-response-table th,
.ai-response-table td {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.ai-response-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.ai-response-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

/* Code display */
.code-display {
  background-color: var(--sidebar-bg);
  border-radius: 8px;
  overflow: hidden;
  margin: 1.5rem 0;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.code-header {
  display: flex;
  align-items: center;
  background-color: var(--sidebar-hover);
  padding: 0.75rem 1rem;
  color: var(--sidebar-text);
}

.code-back-icon {
  margin-right: 0.75rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.code-back-icon:hover {
  color: var(--primary-color);
}

.code-language {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Math rendering */
.katex-display {
  margin: 2em 0;
  overflow-x: auto;
  overflow-y: hidden;
  background: linear-gradient(135deg, var(--sidebar-hover), var(--sidebar-active));
  padding: 1.5em;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.katex-display:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.katex {
  font-size: 1.2em;
  line-height: 1.4;
}

.katex-display > .katex {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  font-family: 'KaTeX_Main', 'Times New Roman', serif;
}

.math-block {
  display: block;
  overflow-x: auto;
  padding: 1.5em 0;
  position: relative;
}

.math-block::before {
  content: '∑';
  position: absolute;
  top: 0.5em;
  left: -1em;
  font-size: 1.5em;
  color: var(--primary-color);
  opacity: 0.5;
}

.math-inline {
  display: inline-block;
  vertical-align: middle;
  padding: 0 0.2em;
  background-color: var(--sidebar-hover);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.math-inline:hover {
  background-color: var(--sidebar-active);
}

/* Add some color to specific math elements */
.katex .mord.mathdefault {
  color: var(--primary-color);
}

.katex .mop {
  color: var(--accent-color);
}

.katex .mbin {
  color: var(--secondary-color);
}

.katex .mrel {
  color: #f57c00;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Syntax highlighting */
.code-display .token.comment,
.code-display .token.prolog,
.code-display .token.doctype,
.code-display .token.cdata {
  color: #008000;
}

.code-display .token.punctuation {
  color: #000000;
}

.code-display .token.property,
.code-display .token.tag,
.code-display .token.boolean,
.code-display .token.number,
.code-display .token.constant,
.code-display .token.symbol,
.code-display .token.deleted {
  color: #0000ff;
}

.code-display .token.selector,
.code-display .token.attr-name,
.code-display .token.string,
.code-display .token.char,
.code-display .token.builtin,
.code-display .token.inserted {
  color: #a31515;
}

.code-display .token.operator,
.code-display .token.entity,
.code-display .token.url,
.code-display .language-css .token.string,
.code-display .style .token.string {
  color: #000000;
}

.code-display .token.atrule,
.code-display .token.attr-value,
.code-display .token.keyword {
  color: #0000ff;
}

.code-display .token.function,
.code-display .token.class-name {
  color: #795e26;
}

.code-display .token.regex,
.code-display .token.important,
.code-display .token.variable {
  color: #ee0000;
}

/* Media Queries */
@media (max-width: 768px) {

  .chat-header {
    padding: 1rem;
  }

  .input-area {
    padding: 0.5rem;
  }


  .input-area button {
    width: 40px;
    height: 40px;
  }
}

.default-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;
  background-color: var(--sidebar-bg);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--sidebar-text);
}

.welcome-section {
  margin-bottom: 2rem;
}

.welcome-icon {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.default-display h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--sidebar-text);
}

.default-display p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: var(--text-secondary);
  max-width: 600px;
}

.suggestions-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.suggestion-item {
  background-color: var(--sidebar-hover);
  border: 1px solid var(--sidebar-border);
  border-radius: 8px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.suggestion-item:hover {
  background-color: var(--sidebar-active);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.suggestion-icon {
  color: var(--primary-color);
  margin-right: 0.5rem;
}

.suggestion-item span {
  font-size: 0.9rem;
  color: var(--sidebar-text);
}

@media (max-width: 768px) {
  .default-display {
    padding: 1rem;
  }

  .default-display h2 {
    font-size: 1.5rem;
  }

  .default-display p {
    font-size: 0.9rem;
  }

  .suggestions-list {
    flex-direction: column;
  }

  .suggestion-item {
    width: 100%;
  }
}

.chat-limit-banner {
  color: #856404;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
}



.input-wrapper {
  position: relative;
}
