.custom-toast {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .custom-toast.success {
    background-color: #e6f7e6;
    color: #2e7d32;
    border-left: 4px solid #2e7d32;
  }
  
  .custom-toast.error {
    background-color: #fdecea;
    color: #d32f2f;
    border-left: 4px solid #d32f2f;
  }
  
  .toast-icon {
    margin-right: 12px;
    font-size: 18px;
  }
  
  .toast-message {
    flex: 1;
  }
  
  /* Override react-toastify default styles */
  .Toastify__toast-container {
    width: auto;
    max-width: 400px;
  }
  
  .Toastify__toast {
    padding: 0;
    background: none;
    box-shadow: none;
  }
  
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
  }
  
  .Toastify__close-button {
    color: inherit;
    opacity: 0.7;
  }