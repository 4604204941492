:root {
  --primary-color: #1976d2;
  --secondary-color: #388e3c;
  --accent-color: #d32f2f;
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --background-dark: #e0e0e0;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;
  --border-color: #e0e0e0;
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --error-color: #f44336;
  --hover-color: rgba(25, 118, 210, 0.1);
  --active-color: rgba(25, 118, 210, 0.2);
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --font-family: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
}

.settings-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: var(--font-family);
  color: var(--text-primary);
}

.settings-header {
  margin-bottom: 2rem;
}

.settings-header h1 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--text-primary);
}

.settings-content {
  display: flex;
  gap: 2rem;
}

.settings-nav {
  flex: 0 0 200px;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--text-primary);
  background: none;
  border: none;
  width: 100%;
  text-align: left;
}

.nav-item:hover {
  background-color: var(--hover-color);
}

.nav-item.active {
  background-color: var(--active-color);
  font-weight: 600;
}

.settings-main {
  flex: 1;
}

.settings-section {
  background-color: var(--background-light);
  padding: 2rem;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
}

.settings-section h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-primary);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--text-primary);
}

.settings-section input[type="text"],
.settings-section input[type="email"],
.settings-section input[type="tel"],
.settings-section input[type="password"],
.settings-section select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  color: var(--text-primary);
  background-color: var(--background-main);
}

.settings-section input[type="text"]:focus,
.settings-section input[type="email"]:focus,
.settings-section input[type="tel"]:focus,
.settings-section input[type="password"]:focus,
.settings-section select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.toggle-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.toggle-button.active {
  color: var(--secondary-color);
}

.submit-button,
.stripe-portal-button,
.change-password-button {
  background-color: var(--secondary-color);
  color: var(--text-light);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1rem;
  display: inline-block;
}

.change-password-button{
  margin-right: 5px;
}

.changepassword-button{
  background-color: #2e7d32;;
  margin-bottom: 4px;
  color: var(--text-light);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover,
.stripe-portal-button:hover,
.change-password-button:hover {
  background-color: #2e7d32;
  transform: translateY(-2px);
}

.changepassword-button:hover {
  background-color: #2e7d32;
  transform: translateY(-2px);
}

.delete-account-button {
  background-color: var(--error-color);
  color: var(--text-light);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1rem;
}

.delete-account-button:hover {
  background-color: #d32f2f;
  transform: translateY(-2px);
}

.error-message {
  color: var(--error-color);
  margin-bottom: 1rem;
}

.success-message {
  color: var(--success-color);
  margin-bottom: 1rem;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: var(--text-secondary);
}

.spinner-icon {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.subscription-info {
  margin-bottom: 2rem;
}

.subscription-info p {
  margin-bottom: 1rem;
}

.security-settings {
  margin-top: 2rem;
}

.security-settings h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.forgot-password-link {
  display: inline-block;
  color: var(--text-secondary);
  text-decoration: none;
  margin-top: 1rem;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .settings-content {
    flex-direction: column;
  }

  .settings-nav {
    flex: none;
    margin-bottom: 2rem;
  }

  .nav-item {
    padding: 0.5rem 0.75rem;
  }

  .submit-button,
  .stripe-portal-button,
  .change-password-button,
  .delete-account-button {
    width: 100%;
    margin-top: 1rem;
  }
}