@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define custom font variables */
:root {
  --font-primary: 'Inter', sans-serif;
}

body {
  font-family: var(--font-primary);
}


main {
  flex: 1;
}

.content {
  font-family: var(--font-primary);
}

.app-container.with-sidebar {
  flex-direction: row;
}

/**
universal nav styling
**/

.top-nav, .top-nav2 {
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 1.5rem;
  height: auto;
}

.top-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.top-nav2{
  display: flex; /* This ensures items use all the space */
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 70px; 
}

.nav-link, .sign-in-button, .profile-button {
  padding: 0.5rem 1rem; /* Adjust padding for more space around the items */
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end; /* Add this line */
  flex-grow: 1; /* Add this line */
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: black;
  transform: scale(1.03);
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding-left: 40px;
}

.top-nav2{
  display: none;
}

@media (max-width: 840px) {
  .top-nav{display: none;}

  .top-nav2 {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-light);
    padding: 1rem;
    z-index: 999;
    width: 100%;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-toggle {
    display: block;
  }

  .nav-links {
    justify-content: flex-start; /* Change this for mobile view */
  }

  .logout-button {
    background-color: #fff5f5;
    color: #dc3545;
    max-width: 100px;

}

.logout-button:hover {
    background-color: #fff5f5;
    color: #dc3545;
}
}

.settings-dropdown {
    position: relative;
}

.settings-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition: color 0.3s ease;
}

.dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.15);
    z-index: 1000;
    min-width: 200px;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.settings-dropdown:hover .dropdown-menu {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    color: #333;
    text-decoration: none;
    transition: background-color 0.2s, color 0.2s;
}

.dropdown-item:hover {
    background-color: #f0f8ff;
}
.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;  /* Implement a transparent border */
  outline: none;  /* Remove outline */
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.chat-input:focus {
  border: 1px solid transparent;  /* Ensure transparent border on focus */
  outline: none;  /* Ensure no outline on focus */
}

.logout-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #dc3545;
    padding: 0.75rem 1rem;
    transition: background-color 0.2s, color 0.2s;
}

.logout-button:hover {
    background-color: #fff5f5;
    color: #dc3545;
}

