.footer {
    background-color: var(--background-light);
    color: var(--text-color);
    padding: 2rem 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .footer-section {
    margin-bottom: 1.5rem;
  }
  
  .footer-section h4 {
    color: var(--primary-color);
    margin-bottom: 0.5rem;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5rem;
  }

  .footer-section ul li a {
    color: var(--text-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section a {
    color: var(--text-color);
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section a:hover {
    color: var(--primary-color);
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .theme-picker-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-section {
      text-align: center;
      margin-bottom: 2rem;
    }

    .theme-picker-container {
      justify-content: center;
    }
  }