/* Root variables */
:root {
  --primary-color: #1976d2;
  --secondary-color: #388e3c;
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --background-dark: #e0e0e0;
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;
  --accent-color: #d32f2f;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --hover-color: rgba(25, 118, 210, 0.1);
  --highlight-background: #f9f9f9;; /* New variable for highlight background */
}

/* Layout */
.chat-with-pdf-page {
  display: flex;
  height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--background-main);
  width: 100%;
  color: var(--text-primary);
}

.chat-with-pdf-area {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s ease;
}

.chat-with-pdf-area.sidebar-open {
  margin-left: 250px;
}

/* Header */
.chat-header {
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--background-light);
  box-shadow: 0 2px 4px var(--shadow-color);
  padding: 1.579rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error-message {
    background-color: #ffebee;
    color: #c62828;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }

.menu-button,
.settings-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-primary);
}

.menu-button:hover,
.settings-button:hover {
  background: none !important;
}

/* File upload area */
.file-upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 2px dashed var(--background-dark);
  border-radius: 8px;
  margin: 2rem;
  cursor: pointer;
}

.file-upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--text-secondary);
}

/* Messages container */
.messages-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

/* Message styles */
.message {
  max-width: 80%;
  margin-bottom: 1.5rem;
  padding: 1rem 1.25rem;
  border-radius: 18px;
  line-height: 1.5;
  animation: fadeIn 0.3s ease;
  box-shadow: 0 2px 5px var(--shadow-color);
}

.message.user {
  align-self: flex-end;
  background-color: black;
  color: var(--text-light);
}

.message.ai {
  align-self: flex-start;

  color: var(--text-primary);
}

.message-content {
  font-size: 1rem;
}

.message-info {
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  opacity: 0.7;
}

/* Input area */
.input-area {
  backdrop-filter: blur(10px);
  outline: none;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  padding: 1rem;
  background-color: rgba(var(--background-light-rgb), 0.8);
  box-shadow: 0 -2px 10px rgba(var(--shadow-color-rgb), 0.1);
}

.input-wrapper {
  outline: none;
  display: flex;
  align-items: center;
  background-color: var(--background-main);
  border-radius: 24px;
  padding: 0.5rem;
  box-shadow: 0 2px 6px rgba(var(--shadow-color-rgb), 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  border: 1px solid #1c1c1c;
}

.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;  /* Implement a transparent border */
  outline: none;  /* Remove outline */
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.chat-input:focus {
  border: 1px solid transparent;  /* Ensure transparent border on focus */
  outline: none;  /* Ensure no outline on focus */
}

.input-wrapper:focus-within {
}

.input-area input {
  flex-grow: 1;
  padding: 0.75rem 1.5rem;
  background: transparent;
  font-size: 1rem;
  border: none;
  outline: none;
}

.input-area input:focus {
  outline: none;
  border: none;
  background-color: white;
}

textarea:focus, input:focus{
  outline: none;
}
.send-button {
  background-color: #1c1c1c;
  color: var(--text-light);
  border: none;
  border-radius: 50%;
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-right: 0.25rem;
}

.send-button:hover {
  background-color: #1c1c1c;
  transform: scale(1.05);
}

.send-button:disabled {
  background-color: var(--background-dark);
  cursor: not-allowed;
  transform: none;
}

/* Media Queries */
@media (max-width: 768px) {
  .chat-with-pdf-area.sidebar-open {
    margin-left: 0;
  }

  .chat-header {
    padding: 1rem;
  }

  .message {
    max-width: 90%;
  }

  .input-area {
    padding: 0.75rem;
  }

  .input-wrapper {
    padding: 0.25rem;
  }

  .input-area input {
    padding: 0.5rem 0.75rem;
  }

  .send-button {
    width: 36px;
    height: 36px;
  }
}

.pdf-preview {
  border: 1px solid #ccc;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.pdf-preview.expanded {
  position: fixed;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 1000;
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.pdf-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: var(--highlight-background); /* Updated */
}

.pdf-preview-header button {
  background: none;
  border: none;
  cursor: pointer;
}