.code-preview-box {
  display: flex;
  align-items: center;
  background-color: #2d2d2d;
  border-radius: 8px;
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #3a3a3a;
  margin: 10px 0;
}

.code-preview-box:hover {
  background-color: #3a3a3a;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.code-icon {
  font-size: 20px;
  margin-right: 12px;
  color: #61dafb;
}

.code-preview-text {
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
  color: #e6e6e6;
  font-size: 14px;
  font-weight: 600;
}

.code-preview-hint {
  margin-left: auto;
  font-size: 12px;
  color: #a0a0a0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: auto;
  background-color: #2d2d2d;
  border-radius: 12px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #2d2d2d;
  border-bottom: 1px solid #3a3a3a;
}

.modal-title {
  color: #e6e6e6;
  font-size: 18px;
  font-weight: bold;
}

.modal-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.action-button {
  background: none;
  border: none;
  color: #d4d4d4;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-text {
  display: inline;
}

.code-container {
  max-height: calc(90vh - 60px);
  overflow-y: auto;
}

.code-container pre {
  margin: 0 !important;
  padding: 20px !important;
  border-radius: 0 0 12px 12px !important;
}

.code-container::-webkit-scrollbar {
  width: 12px;
}

.code-container::-webkit-scrollbar-track {
  background: #2d2d2d;
}

.code-container::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 6px;
  border: 3px solid #2d2d2d;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 0 10px;
  }

  .modal-header {
    align-items: center;
  }

  .modal-actions {
    margin-left: 96px;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
  }

  .button-text {
    display: none;
  }
}

.code-display .token.comment,
.code-display .token.prolog,
.code-display .token.doctype,
.code-display .token.cdata {
  color: #608b4e;
}

.code-display .token.punctuation {
  color: #d4d4d4;
}

.code-display .token.property,
.code-display .token.tag,
.code-display .token.boolean,
.code-display .token.number,
.code-display .token.constant,
.code-display .token.symbol,
.code-display .token.deleted {
  color: #569cd6;
}

.code-display .token.selector,
.code-display .token.attr-name,
.code-display .token.string,
.code-display .token.char,
.code-display .token.builtin,
.code-display .token.inserted {
  color: #ce9178;
}

.code-display .token.operator,
.code-display .token.entity,
.code-display .token.url,
.code-display .language-css .token.string,
.code-display .style .token.string {
  color: #d4d4d4;
}

.code-display .token.atrule,
.code-display .token.attr-value,
.code-display .token.keyword {
  color: #c586c0;
}

.code-display .token.function,
.code-display .token.class-name {
  color: #dcdcaa;
}

.code-display .token.regex,
.code-display .token.important,
.code-display .token.variable {
  color: #d16969;
}
