:root {
    --confirmation-background: var(--background-light);
    --confirmation-highlight: rgba(25, 118, 210, 0.1);
  }
  
  .payment-confirmation {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
      font-family: var(--font-primary);
  }
  
  .confirmation-content {
      text-align: center;
      background-color: var(--confirmation-background);
      padding: 3rem;
      border-radius: 20px;
      box-shadow: var(--box-shadow);
      max-width: 500px;
      width: 90%;
  }
  
  .confirmation-icon {
      font-size: 5rem;
      color: var(--accent-color);
      margin-bottom: 1.5rem;
  }

  
  .loader {
      border: 4px solid var(--confirmation-highlight);
      border-top: 4px solid var(--accent-color);
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 1s linear infinite;
      margin: 2rem auto;
  }
  
  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 768px) {
      .confirmation-content {
          padding: 2rem;
      }
  
      h1 {
          font-size: 2rem;
      }
  
      p {
          font-size: 1rem;
      }
  
      .confirmation-icon {
          font-size: 4rem;
      }
  }