.learning-options {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.learning-options h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.options-grid {
  display: flex;
  overflow-x: auto;
  gap: 2rem;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
  justify-content: center;
}

.option-card {
  flex: 0 0 300px;
  scroll-snap-align: start;
  background-color: var(--background-light);
  border-radius: 20px;
  padding: 2.5rem;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: var(--box-shadow);
}

.option-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 48px rgba(0, 0, 0, 0.2);
}

.card-content {
  position: relative;
  z-index: 1;
}

.option-card h2 {
  margin-bottom: 1rem;
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 600;
}

.option-card p {
  color: var(--text-color);
  margin-bottom: 1.5rem;
  font-size: 1rem;
  line-height: 1.6;
}

.option-card .icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  color: var(--secondary-color);
}

.option-card .learn-more {
  background-color: var(--accent-color);
  color: var(--text-light);
  padding: 0.8rem 2rem;
  border-radius: 30px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  display: inline-block;
  margin-top: 1rem;
  text-decoration: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.option-card .learn-more:hover {
  background-color: #e53e3e;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.options-grid::-webkit-scrollbar {
  display: none;
}

.options-grid {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

@media (max-width: 768px) {
  .learning-options h1 {
    font-size: 2rem;
  }

  .option-card h2 {
    font-size: 1.5rem;
  }

  .option-card .icon {
    font-size: 2.5rem;
  }

  .option-card {
    flex: 0 0 250px;
  }
}