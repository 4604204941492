:root {
  /* Main colors */
  --primary-color: #1976d2;
  --secondary-color: #388e3c;
  --accent-color: #d32f2f;

  /* Background colors */
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --background-dark: #e0e0e0;

  /* Text colors */
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;

  /* Border colors */
  --border-color: #e0e0e0;

  /* Status colors */
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --error-color: #f44336;

  /* Interaction colors */
  --hover-color: rgba(25, 118, 210, 0.1);
  --active-color: rgba(25, 118, 210, 0.2);

  /* Shadow */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Font */
  --font-family: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}
.chat-input {
  width: 100%;
  padding: 10px;
  border: 1px solid transparent;  /* Implement a transparent border */
  outline: none;  /* Remove outline */
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.chat-input:focus {
  border: 1px solid transparent;  /* Ensure transparent border on focus */
  outline: none;  /* Ensure no outline on focus */
}
body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
  background-color: var(--background-main);
  color: var(--text-primary);
  height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: black;
}

.loading-container{
  height: 100vh;
}