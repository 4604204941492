:root {
  --primary-color: #1976d2;
  --secondary-color: #388e3c;
  --accent-color: #d32f2f;
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --text-color: #333333;
  --font-primary: 'Roboto', sans-serif;
}

body {
  background: var(--background-main);
  color: var(--text-color);
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: var(--font-primary);
  line-height: 1.6;
}

.home-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: max-content;
}


.top-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--background-light);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.top-nav, .top-nav2 {
  padding: 1rem 1.5rem; /* Increase padding for a taller nav bar */
  height: auto; /* If a specific height is set, ensure it aligns with your design */
}

.logo {
  font-size: 1.5rem; /* Increase font size if you want the logo to be more prominent */
  padding-right: 60px;
}

.nav-link, .sign-in-button, .profile-button {
  padding: 0.5rem 1rem; /* Adjust padding for more space around the items */
}

.top-nav2{
  display: flex; /* This ensures items use all the space */
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--background-light);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 70px; 
}

.logo {
  font-size: 1.4rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: bold;
  flex-grow: 1;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end; /* Add this line */
  flex-grow: 1; /* Add this line */
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  padding-left: 40px;
}

.sign-in-button, .profile-button {
  background: none;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  padding-left: 17px;
  border-radius: 20px;
  cursor: pointer;
  white-space: nowrap; /* Prevent text from wrapping */
}


.logo:hover {
  color: var(--primary-color);
}

.sign-in-button:hover, .profile-button:hover {
  background-color: var(--text-color);
  color: var(--background-main);
}

.hero {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--text-light);
  text-align: center;
  padding: 5rem 2rem;
  border-radius: 0 0 50% 50% / 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: white;
}

.hero p {
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto 2rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero h1, .hero p {
  text-shadow: none;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.cta-button, .signup-button {
  margin-top: 20px;
  padding-top: 15px;
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--text-light);
  padding: 0.8rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.cta-button:hover, .signup-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-button.primary {
  background-color: var(--accent-color);
  color: var(--text-light);
}

.cta-button.secondary {
  background-color: transparent;
  color: black;
  border: 2px solid black;
}

.cta-button.secondary:hover {
  color: black;
}

.features, .pricing, .testimonials {
  padding: 4rem 2rem;
  text-align: center;
}

.features h2, .pricing h2, .testimonials h2 {
  color: var(--text-primary);
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.feature-grid {
  display: flex;
  overflow-x: auto;
  gap: 2rem;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
  justify-content: center;
}

.feature-item {
  flex: 0 0 300px;
  scroll-snap-align: start;
  background-color: var(--background-light);
  color: var(--text-primary);
  box-shadow: var(--box-shadow);
  padding: 2.5rem;
  border-radius: 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-item:hover {
  transform: translateY(-8px);
}

.feature-item svg {
  margin-bottom: 1.5rem;
}

.feature-item h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
  font-weight: 600;
}

.feature-item p {
  font-size: 1rem;
  line-height: 1.6;
}

.featured-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: var(--primary-color);
  font-size: 2rem;
}


.testimonial-item {
  font-style: italic;
  font-size: 1.1rem;
  background-color: var(--background-light);
  color: var(--text-primary);
  padding: 2rem;
  border-radius: 20px;
  margin-bottom: 2rem;
}

.author {
  font-style: normal;
  font-weight: 600;
  margin-top: 1rem;
  color: var(--primary-color);
}

.feature-grid::-webkit-scrollbar,
.pricing-grid::-webkit-scrollbar {
  display: none;
}

.feature-grid,
.pricing-grid {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.top-nav2{
  display: none;
}

@media (max-width: 840px) {
  .top-nav{display: none;}

  .top-nav2 {
    display: flex;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .features, .pricing, .testimonials {
    padding: 2rem 1rem;
  }

  .feature-item {
    flex: 0 0 250px;
  }

  .pricing-grid {
    justify-content: flex-start; /* Ensure this is set to flex-start for mobile as well */
    padding-left: 1rem; /* Adjust padding for mobile */
    padding-right: 1rem;
  }

  .pricing-item {
    width: 100%;
    justify-content: center;
    max-width: 350px;
  }

  .pricing-item.featured {
    transform: scale(1);
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-light);
    padding: 1rem;
    z-index: 999;
    width: 100%;
  }

  .nav-links.open {
    display: flex;
  }

  .nav-toggle {
    display: block;
  }

  .hero-buttons {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 220px;
    
  }

  .cta-button {
    text-align: center;
  }

  .pricing {
    padding: 2rem 1rem;
  }

  .cta-button.secondary {
    margin-top: 1rem;
    width: auto; /* Allow the button to size based on content */
    max-width: none; /* Remove max-width constraint */
  }
}

.pricing-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--primary-color);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.toggle-container span {
    font-weight: 500;
}

.toggle-container span.active {
    color: var(--primary-color);
}

.pricing-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.price {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 1rem 0 0.5rem;
    color: var(--primary-color);
}

.billing-cycle {
    font-size: 1rem;
    color: var(--text-color);
}

.annual-savings {
    color: var(--accent-color);
    font-weight: 600;
    margin-top: 0;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0;
    text-align: left;
}

.feature-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.feature-check {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.signup-button {
    margin-top: auto;
    width: 100%;
}