:root {
  --feature-background: var(--background-light);
  --feature-highlight: rgba(25, 118, 210, 0.1);
  --feature-highlight-alt: rgba(56, 142, 60, 0.1);
  --font-primary: 'Inter', sans-serif;
}

body {
  font-family: var(--font-primary);
}

.dev-updates {
  background: var(--background-main);
  color: var(--text-primary);
  padding: 2rem;
}

.dev-header {
  background: linear-gradient(135deg, var(--feature-highlight), var(--feature-highlight-alt));
  color: var(--text-primary);
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.dev-header h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.back-link {
  display: inline-block;
  margin-bottom: 2rem;
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.back-link:hover {
  color: var(--primary-color);
}

.update-item {
  background-color: var(--feature-background);
  border-radius: 10px;
  align-items: center;
  text-align: left;
  padding: 1.5rem;
  margin-bottom: 3rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: var(--box-shadow);
}

.update-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.update-item h2 {
  color: var(--secondary-color);
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.update-date {
  font-size: 0.9rem;
  color: var(--text-secondary);
  opacity: 0.7;
  margin-bottom: 1rem;
}

.update-item p {
  color: var(--text-primary);
  line-height: 1.6;
  margin-bottom: 1rem;
}

.feature-list {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.feature-list li {
  margin-bottom: 0.5rem;
  color: var(--text-primary);
}