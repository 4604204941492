:root {
    --pricing-background: var(--background-light);
    --pricing-highlight: rgba(25, 118, 210, 0.1);
    --pricing-highlight-alt: rgba(56, 142, 60, 0.1);
}

.pricing-page {
    background: var(--background-main);
    color: var(--text-color);
    min-height: 100vh;
    font-family: var(--font-primary);
}

.pricing-header {
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--text-light);
    text-align: center;
    padding: 6rem 2rem;
    border-radius: 0 0 50% 50% / 60px;
}

.pricing-header h1 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
    color: var(--text-light);
    font-weight: 800;
    letter-spacing: -0.025em;
    line-height: 1.2;
}

.pricing-header p {
    font-size: 1.5rem;
    max-width: 700px;
    margin: 0 auto;
    font-weight: 400;
    opacity: 0.9;
}

.pricing-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    gap: 2rem;
}

.toggle-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--primary-color);
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.toggle-container span {
    font-weight: 500;
}

.toggle-container span.active {
    color: var(--primary-color);
}

.pricing-grid {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 4rem auto;
    max-width: 1200px;
    padding: 0 2rem;
}

.pricing-item {
    background-color: var(--pricing-background);
    border-radius: 20px;
    padding: 3rem;
    flex: 1;
    min-width: 120px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.pricing-item.featured {
    background-color: var(--pricing-highlight);
    border: 2px solid var(--primary-color);
    transform: scale(1.05);
}

.pricing-item h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.price {
    font-size: 3rem;
    font-weight: 700;
    margin: 1rem 0 0.5rem;
    color: var(--primary-color);
}

.billing-cycle {
    font-size: 1rem;
    color: var(--text-color);
}

.annual-savings {
    color: var(--accent-color);
    font-weight: 600;
    margin-top: 0;
}

.free-trial {
    font-style: italic;
    margin-bottom: 1rem;
}

.feature-list {
    list-style-type: none;
    padding: 0;
    margin: 1.5rem 0;
    text-align: left;
}

.feature-list li {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.feature-check {
    color: var(--primary-color);
    margin-right: 0.5rem;
}

.signup-button {
    margin-top: auto;
    width: 100%;
    background-color: var(--accent-color);
    color: var(--text-light);
    padding: 1rem;
    border: none;
    border-radius: 30px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.signup-button:hover {
    transform: translateY(-2px);
    color: white;
}

.faq {
    background-color: var(--pricing-background);
    padding: 6rem 2rem;
    text-align: center;
}

.faq h2 {
    font-size: 3rem;
    margin-bottom: 3rem;
    color: var(--text-color);
}

.faq-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.faq-item {
    background-color: var(--background-main);
    padding: 2rem;
    border-radius: 20px;
    text-align: left;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.faq-item h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.cta {
    padding: 6rem 2rem;
    text-align: center;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--text-light);
}

.cta h2 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.cta p {
    font-size: 1.2rem;
    max-width: 700px;
    margin: 0 auto 2rem;
}

.cta-button {

}

.cta-button:hover {
    color: white;
}


@media (max-width: 1200px) {
    .pricing-grid {
        flex-wrap: wrap;
    }

    .pricing-item {
        flex-basis: calc(50% - 1rem);
        max-width: none;
    }
}

@media (max-width: 768px) {
    .pricing-header h1 {
        font-size: 3rem;
    }

    .pricing-header p {
        font-size: 1.2rem;
    }

    .pricing-controls {
        flex-direction: column;
        gap: 1rem;
    }

    .pricing-grid {
        flex-direction: column;
        align-items: center;
    }

    .pricing-item {
        width: 100%;
        max-width: 350px;
    }

    .faq-grid {
        grid-template-columns: 1fr;
    }

    .cta h2 {
        font-size: 2.5rem;
    }

    .cta p {
        font-size: 1rem;
    }
}