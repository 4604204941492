:root {
  /* Main colors */
  --primary-color: #d32f2f;
  --secondary-color: #388e3c;
  --accent-color: #d32f2f;

  /* Background colors */
  --background-main: #ffffff;
  --background-light: #f5f5f5;
  --background-dark: #e0e0e0;

  /* Text colors */
  --text-primary: #333333;
  --text-secondary: #666666;
  --text-light: #ffffff;

  /* Border colors */
  --border-color: #e0e0e0;

  /* Status colors */
  --success-color: #4caf50;
  --warning-color: #ff9800;
  --error-color: #f44336;

  /* Interaction colors */

  /* Shadow */
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  /* Font */
  --font-family: 'Inter', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica', 'Arial', sans-serif;
  --font-size-base: 16px;
  --line-height-base: 1.5;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  /* Sidebar colors */
  --sidebar-bg: #1c1c1c;
  --sidebar-text: #e0e0e0;
  --sidebar-hover: #2a2a2a;
  --sidebar-active: #333333;
  --sidebar-header: #242424;
  --sidebar-header-text: #ffffff;
  --sidebar-border: #3a3a3a;
  --sidebar-accent: #ffa07a;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
  background-color: var(--background-main);
  color: var(--text-primary);
}

a {
  color: var(--primary-color);
  text-decoration: none;
}


button {
  background-color: var(--primary-color);
  color: var(--text-light);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:disabled {
  background-color: var(--background-dark);
  color: var(--text-secondary);
  cursor: not-allowed;
}

input, textarea {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 0.5rem;
  font-family: var(--font-family);
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.sidebar {
  background-color: var(--sidebar-bg);
  color: var(--sidebar-text);
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Fallback for browsers that don't support dvh */
  height: 100dvh; /* Dynamic viewport height */
  width: 250px;
  transition: all 0.3s ease;
  left: 0;
  top: 0;
  z-index: 1000;
}

.sidebar.collapsed {
  width: 0;
  overflow: hidden;
}

.sidebar-header {
  padding: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--sidebar-border);
  background-color: var(--sidebar-header);
  font-weight: var(--font-weight-medium);
}

.user-email {
  font-size: 0.9rem;
  font-weight: var(--font-weight-normal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 0.5rem;
}

.collapse-btn {
  background: none;
  border: none;
  color: var(--sidebar-header-text);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.2s ease;
}

.collapse-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-nav {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
}

.scrollable-section {
  flex: 0 1 auto;
  overflow-y: auto;
  margin-top: 0.5rem;/* Dynamic viewport height */
  min-height: 0vh;
  scrollbar-width: thin;
  scrollbar-color: var(--sidebar-border) var(--sidebar-bg);
}

.scrollable-section::-webkit-scrollbar {
  width: 6px;
  display: none;
}

.scrollable-section:hover::-webkit-scrollbar {
  display: block;
}

.scrollable-section::-webkit-scrollbar-track {
  background: var(--sidebar-bg);
}

.scrollable-section::-webkit-scrollbar-thumb {
  background-color: var(--sidebar-border);
  border-radius: 3px;
}

.sidebar-nav section {
  margin-bottom: 2rem;
}

.sidebar-nav h3 {
  font-size: 0.9rem;
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  color: var(--sidebar-accent);
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  margin-bottom: 0.5rem;
}

.sidebar-nav a {
  color: var(--sidebar-text);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: var(--font-weight-normal);
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.sidebar-nav a .chat-title {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}

.sidebar-nav a .button-group {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.sidebar-nav a:hover {
  color: var(--sidebar-text);
  border: 1px solid var(--sidebar-border);
}

.sidebar-nav a.active {
  background-color: var(--sidebar-active);
  border: none;
  color: var(--sidebar-text);
  font-weight: var(--font-weight-bold);
  border: 1px solid var(--sidebar-border);
}

.add-btn, .logout-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: var(--font-weight-medium);
  color: var(--sidebar-text);
  padding: 0.75rem;
  border-radius: 6px;
  transition: background-color 0.2s ease;
  width: 100%;
  justify-content: center;
  margin-bottom: 1rem;
  align-self: center;
  border: 1px solid var(--sidebar-border);
}


h1{
  color: #d32f2f;
}


.add-btn:hover, .logout-btn:hover {
   background-color: var(--sidebar-hover);
}

.logout-btn {
  border: none;
  border-radius: 0;
  padding-top: 10px;
  margin-top: auto;
  background-color: var(--sidebar-bg);
}

.edit-btn, .remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  border-radius: 4px;
  transition: background-color 0.2s ease;
  margin-left: 0.25rem;
}

.edit-btn:hover, .remove-btn:hover {
  background: none !important;
  transform: scale(1.5);
  transition: transform 0.2s ease;
}

.remove-btn {
  color: var(--accent-color);
}

.sidebar-nav a:hover {
  background-color: transparent;
}

.sidebar-nav a.active {
  border: 1px solid var(--sidebar-border);
  background-color: black;
}

.sidebar-nav form {
  display: flex;
  align-items: center;
  padding: 0.75rem;
}

.sidebar-nav form input {
  flex-grow: 1;
  background-color: var(--sidebar-bg);
  color: var(--sidebar-text);
  border: 1px solid var(--sidebar-border);
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 0.9rem;
  font-family: var(--font-family);
}

.sidebar-nav form input:focus {
  outline: none;
  border-color: var(--sidebar-accent);
}

.logout-btn {
  background-color: var(--sidebar-bg);
}

.logout-btn:hover{
  background-color: var(--sidebar-hover);
}

.collapse-btn{
  display: none;
}

.user-email{
  align-content: first baseline;
}


@media (max-width: 768px) {
  .collapse-btn{
    display: block;
  }

  .sidebar-nav {
    padding: 1rem;
  }

  .scrollable-section {
    max-height: calc(100vh - 200px); /* Fallback */
    max-height: calc(100svh - 200px); /* Small viewport height for mobile */
  }

  .add-btn, .logout-btn {
    padding: 0.5rem;
  }

  .add-btn{
    margin-top: 0px;
  }
}
