:root {
  --input-background: var(--background-light);
  --input-border: var(--border-color);
  --input-focus-border: var(--primary-color);
  --button-hover: #1565c0;
}

.contact-us-page {
  background: var(--background-main);
  color: var(--text-primary);
  min-height: 100vh;
  padding-bottom: 2rem;
}

.contact-hero {
  background: var(--background-light);
  color: var(--text-primary);
  text-align: center;
  padding: 4rem 2rem;
}

.contact-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.contact-hero p {
  font-size: 1.2rem;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--input-background);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--primary-color);
}

.email-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background-color: var(--background-light);
  border-radius: 5px;
}

.email-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 1rem;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form input,
.contact-form textarea {
  padding: 0.75rem;
  border: 1px solid var(--input-border);
  border-radius: 5px;
  background-color: var(--input-background);
  color: var(--text-primary);
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-button {
  background-color: var(--primary-color);
  color: var(--text-light);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  font-weight: bold;
}

.submit-button:hover {
  background-color: var(--button-hover);
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .contact-form {
    padding: 1.5rem;
    margin: 1rem;
  }

  .contact-hero h1 {
    font-size: 2rem;
  }

  .contact-hero p {
    font-size: 1rem;
  }
}

/* High Contrast Mode */
[data-theme="high-contrast"] .contact-us-page {
  --input-background: var(--background-dark);
  --input-border: var(--text-light);
  --input-focus-border: var(--text-light);
  --button-hover: var(--text-light);
}

[data-theme="high-contrast"] .contact-hero h1,
[data-theme="high-contrast"] .contact-form h2,
[data-theme="high-contrast"] .email-icon {
  color: var(--text-light);
}

[data-theme="high-contrast"] .submit-button:hover {
  color: var(--text-primary);
}