:root {
  --feature-background: var(--background-light);
  --feature-highlight: rgba(25, 118, 210, 0.1);
  --feature-highlight-alt: rgba(56, 142, 60, 0.1);
}

body {
  font-family: var(--font-primary);
}

.features-container {
  background: var(--background-main);
  color: var(--text-color);
  min-height: 100vh;
}

.features-header {
  background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
  color: var(--text-light);
  text-align: center;
  padding: 6rem 2rem;
  border-radius: 0 0 50% 50% / 60px;
}

.features-header h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  color: var(--text-light);
  font-weight: 800;
  letter-spacing: -0.025em;
  line-height: 1.2;
}

.features-header p {
  font-size: 1.5rem;
  max-width: 700px;
  margin: 0 auto;
  font-weight: 400;
  opacity: 0.9;
}

.ai-core, .personalization, .tier-benefits, .real-world-impact, .future-learning {
  padding: 6rem 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

h2 {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 2rem;
  color: var(--text-color);
  font-weight: 700;
  line-height: 1.2;
}

.ai-showcase {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  gap: 2rem;
}

.ai-example {
  background-color: var(--background-light);
  border-radius: 20px;
  padding: 3rem;
  flex: 1;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease;
}

.ai-example h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.personalization-features {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 3rem;
}

.personalization-features li {
  background-color: var(--feature-highlight);
  padding: 1rem 1.5rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 500;
}

.tier-comparison {
  display: flex;
  overflow-x: auto;
  gap: 2.5rem;
  margin-top: 4rem;
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
  justify-content: flex-start;
  min-height: 400px;
  padding-left: calc((100% - (300px * 3 + 2.5rem * 2)) / 2); /* Added to show last 3 tiers by default */
}

.tier {
  min-width: 300px;
  padding: 3rem;
  border-radius: 30px;
  transition: all 0.3s ease;
  color: var(--text-color);
  position: relative;
  border: 1px solid var(--border-color);
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
}

.tier h3 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: var(--primary-color);
}

.tier ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px;
}

.tier li {
  margin-bottom: 1rem;
  padding: 0.8rem;
  font-size: 1.1rem;
  background-color: var(--feature-background);
  border-radius: 5px;
}

.tier li:nth-child(even) {
  background-color: var(--feature-highlight-alt);
}

.testimonial {
  background-color: var(--background-light);
  border-radius: 20px;
  padding: 3rem;
  margin-top: 3rem;
  font-style: italic;
  font-size: 1.3rem;
  line-height: 1.6;
  color: var(--text-color);
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--text-light);
  padding: 1rem 2.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  margin-top: 3rem;
  letter-spacing: 0.5px;
}

.cta-button:hover {
  transform: translateY(-2px);
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.features-footer {
  background-color: var(--background-light);
  text-align: center;
  padding: 2rem;
}

.features-footer nav {
  margin-top: 1rem;
}

.features-footer nav a {
  color: var(--text-color);
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s ease;
}

.features-footer nav a:hover {
  color: var(--primary-color);
}

/* Comparison Table Styles */
.comparison {
  overflow-x: auto;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.comparison table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 3rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.comparison th, .comparison td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.comparison th {
  background-color: var(--primary-color);
  color: var(--text-light);
  font-weight: 700;
  position: sticky;
  top: 0;
  z-index: 10;
}

.comparison td {
  background-color: var(--background-light);
  color: var(--text-color);
}

.comparison tr:nth-child(even) td {
  background-color: var(--feature-highlight-alt);
  color: var(--text-color);
}

.comparison tr:hover td {
  background-color: var(--feature-highlight);
}

@media (max-width: 768px) {
  .features-header {
    padding: 4rem 1.5rem;
  }

  .features-header h1 {
    font-size: 3rem;
  }

  .features-header p {
    font-size: 1.2rem;
  }

  .ai-core, .personalization, .tier-benefits, .real-world-impact, .future-learning {
    padding: 4rem 1.5rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  .ai-showcase {
    flex-direction: column;
    align-items: center;
  }

  .ai-example {
    max-width: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }

  .tier-comparison {
    padding-bottom: 2rem;
    padding-left: 1.5rem;
  }

  .tier {
    width: 100%;
    max-width: 350px;
    padding: 2rem;
  }

  .comparison {
    padding: 0 1.5rem;
  }

  .comparison table {
    font-size: 0.9rem;
    width: 100%;
  }

  .comparison th, .comparison td {
    padding: 0.8rem;
  }
}