:root {
    --primary-color: #4a90e2;
    --secondary-color: #50c878;
    --background-dark: #121212;
    --background-light: #1e1e1e;
    --text-color: #f7fafc;
    --accent-color: #f56565;
    --error-color: #e53e3e;
    --success-color: #38a169;
    
    --input-background: rgba(255, 255, 255, 0.1);
    --input-border: rgba(255, 255, 255, 0.1);
    --form-background: rgba(255, 255, 255, 0.05);
  }
  
  .auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, var(--background-dark), var(--background-light));
    padding: 2rem;
  }
  
  .auth-form {
    background-color: var(--form-background);
    backdrop-filter: blur(10px);
    border-radius: 0;
    padding: 2rem;
    width: 100%;
    max-width: 400px;
  }
  
  .branding {
    text-align: left;
    margin-bottom: 2rem;
  }
  
  .logo {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
    display: inline-block;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease;
  }
  
  .logo:hover {
    color: var(--secondary-color);
  }
  
  .tagline {
    font-size: 1rem;
    color: var(--text-color);
    opacity: 0.8;
  }
  
  .auth-form h2 {
    color: var(--text-color);
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    color: var(--text-color);
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid var(--input-border);
    background-color: var(--input-background);
    color: var(--text-color);
    border-radius: 0;
    font-size: 1rem;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: var(--primary-color);
  }
  
  .password-input-wrapper {
    position: relative;
  }

  .toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: var(--text-color);
    cursor: pointer;
  }

  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .changepassword-button{
    width: 100%;
    padding: 0.75rem;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    border-radius: 0;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: var(--secondary-color);
  }
  
  .error-message {
    color: var(--error-color);
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .success-message {
    color: var(--success-color);
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .auth-link {
    text-align: center;
    margin-top: 1rem;
    color: var(--text-color);
  }

  .auth-link a {
    color: var(--primary-color);
    text-decoration: none;
  }

  .auth-link a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 480px) {
    .auth-form {
      padding: 1.5rem;
    }
  }