.policy-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-light);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

.policy-container h1 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.policy-container h2 {
  color: var(--secondary-color);
  font-size: 1.8rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.policy-container p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.policy-container ul {
  list-style-type: none;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.policy-container li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.5rem;
}

.policy-container li::before {
  content: "•";
  color: var(--primary-color);
  position: absolute;
  left: 0;
  top: 0;
}

.policy-container section {
  background-color: rgba(var(--primary-color-rgb), 0.05);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  transition: background-color 0.3s ease;
}

.policy-container section:hover {
  background-color: rgba(var(--primary-color-rgb), 0.1);
}

.policy-container a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.policy-container a:hover {
  color: var(--secondary-color);
  text-decoration: underline;
}

@media (max-width: 768px) {
  .policy-container {
    padding: 1rem;
    margin: 1rem;
  }

  .policy-container h1 {
    font-size: 2rem;
  }

  .policy-container h2 {
    font-size: 1.5rem;
  }
}

/* High Contrast Mode */
[data-theme="high-contrast"] .policy-container {
  background-color: #000000;
  color: #ffffff;
}

[data-theme="high-contrast"] .policy-container h1,
[data-theme="high-contrast"] .policy-container h2 {
  color: #ffffff;
}

[data-theme="high-contrast"] .policy-container section {
  background-color: #333333;
}

[data-theme="high-contrast"] .policy-container a {
  color: #ffffff;
  text-decoration: underline;
}

[data-theme="high-contrast"] .policy-container a:hover {
  color: #ffff00;
}